#navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
}

#drawer {
  background-color: var(--gray-100);
  height: 100%;
  display: flex;
  flex-direction: column;
}

#menu-icon {
  display: none;
  border: none;
  background-color: transparent;
  color: var(--gray-500);
  cursor: pointer;
  border-radius: 100%;
  height: 48px;
  width: 48px;
  transition: 0.5s;
}

#menu-icon-close {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--gray-100);
  color: var(--gray-500);
  cursor: pointer;
  border-radius: 100%;
  height: 48px;
  width: 48px;
  transition: 0.5s;
}

#menu-icon:hover {
  background-color: var(--gray-200);
}

#menu-icon-close:hover {
  background-color: var(--gray-200);
}

.nav-item {
  display: flex;
  align-items: center;
  gap: 16px;
}

@media screen and (max-width: 768px) {
  #menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .nav-item {
    display: none;
  }
}

.nav-link {
  color: var(--gray-500);
  text-decoration: none;
  font-size: 16px;
}

.nav-link:hover {
  text-decoration: underline;
}

#navbar-top-line {
  height: 10px;
  background-color: var(--gray-500);
  opacity: 1;
  background-image: repeating-linear-gradient(45deg, var(--gray-200) 25%, transparent 25%, transparent 75%, var(--gray-200) 75%, var(--gray-200)), repeating-linear-gradient(45deg, var(--gray-200) 25%, var(--gray-500) 25%, var(--gray-500) 75%, #d5dbe1 75%, var(--gray-200));
  background-position: 0 0, 12px 12px;
  background-size: 24px 24px;
}

#footer {
  background-color: var(--gray-600);
  color: var(--gray-200);
  padding-top: 36px;
  padding-bottom: 36px;
}

a.footer-link {
  color: var(--gray-200);
  text-decoration: none;
  font-size: 16px; 
}

a.footer-link:hover {
  text-decoration: underline; 
}

.drawer-text {
  color: var(--gray-500);
  line-height: 140%;
  margin: 0;
  font-size: 16px;
}