.btn {
  font-size: 16px;
  font-family: Poppins;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  background-color: var(--gray-100);
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary {
  color: var(--gray-100);
  background-color: var(--gray-500);
  border: 1px solid var(--gray-500);
  transition: 0.5s;
  cursor: pointer;
}

.btn-primary:hover {
  color: var(--gray-500);
  background-color: var(--gray-100);
}

.btn-secondary {
  color: var(--gray-500);
  background-color: var(--gray-100);
  border: 1px solid var(--gray-500);
  transition: 0.5s;
  cursor: pointer;
}

.btn-secondary:hover {
    color: var(--gray-500);
    background-color: var(--gray-200);
}

.btn-tertiary {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  padding-left: 8px;
  transition: .5s;
  border-radius: 4px;
  color: var(--gray-500)
}

.btn-tertiary:hover {
  background-color: var(--gray-200);
  border: none;
}

.btn-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: .5s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  padding: 4px;
}

.btn-icon:hover {
  background-color: var(--gray-300);
  color: var(--gray-100);
}

.btn-dark-mode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: var(--gray-500);
  color: var(--gray-100);
  border: 1px solid var(--gray-500);
  border-radius: 4px;
  transition: .5s;
}

.btn-dark-mode:hover {
  background-color: var(--gray-100);
  color: var(--gray-500);
}