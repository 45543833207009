@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --gray-600: #0f0f14;
  --gray-500: #30313d;
  --gray-400: #6a7383;
  --gray-300: #9ca3af;
  --gray-200: #d5dbe1;
  --gray-100: #f8fafc;
}

.dark-mode {
  --gray-500: #f8fafc;
  --gray-400: #d5dbe1;
  --gray-300: #9ca3af;
  --gray-200: #6a7383;
  --gray-100: #30313d;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  transition: background-color 0.3s, color 0.3s;
}

.content {
  flex: 1;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Poppins;
  background-color: var(--gray-100);
}

section {
  padding-top: 72px;
  padding-bottom: 72px;
}

h1.heading, .heading {
  color: var(--gray-500);
  line-height: 95%;
  letter-spacing: -5%;
  font-size: clamp(36px, 4vw, 60px);
  margin: 0;
  font-weight: 400;
}

h2.sub-heading, .sub-heading {
  color: var(--gray-500);
  line-height: 95%;
  font-size: clamp(28px, 4vw, 36px);
  margin: 0;
}

h3.sub-title {
  color: var(--gray-500);
  line-height: 140%;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
}

.text {
  color: var(--gray-300);
  line-height: 140%;
  margin: 0;
  font-size: 16px;
}

.width-100 {
  width: 100%;
}

.icon {
  color: var(--gray-500);
  width: 36px;
  height: 36px;
  margin: 0;
  padding: 0;
}

.underline {
  text-decoration: underline;
}

.font-serif {
  font-family: "DM Serif Text", serif;
}

.gray-100 {
  color: #f8fafc !important;
}

.gray-300 {
  color: var(--gray-300) !important;
}

.gray-500 {
  color: var(--gray-500) !important;
}