.sphere-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  overflow: hidden;
  perspective: 350px;
  height: 576px;
  width: 100%;
}

@media screen and (max-width: 900px) {
  div.sphere-container {
    display: none;
  }
}

.sphere {
  position: relative;
  transform-style: preserve-3d;
  animation: rotate 10s linear infinite;
  transform-origin: center;
}

.dot {
  position: absolute;
  width: 5px;
  height: 5px;
  background: var(--gray-500);
  border-radius: 100%;
  transform: translateZ(0);
  backface-visibility: visible;
}

@keyframes rotate {
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: rotateX(360deg) rotateY(360deg); /* Adds rotation around both axes */
  }
}
