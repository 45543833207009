label {
    color: var(--gray-300);
}

input, textarea {
  font-family: Poppins;
  font-size: 16px;
  border: 1px solid var(--gray-200);
  border-radius: 4px;
  padding: 4px;
  padding-left: 8px;
  box-sizing: border-box;
}
